.plus-jakarta {
    font-family: "Plus Jakarta Sans", sans-serif !important;
    font-weight: 600 !important;
    font-style: normal !important;
}

.plus-jakarta-bold {
    font-family: "Plus Jakarta Sans", sans-serif !important;
    font-weight: 650 !important;
    font-style: normal !important;
}

.manjari-thin {
    font-family: "Manjari", sans-serif !important;
    font-weight: 100 !important;
    font-style: normal !important;
}

.navbar-letra {
    font-size: 18px;
    margin-right: 15px;
    transition: color 0.3s;
    cursor: pointer;
    margin-bottom: 5px;
}

.navbar-letra:hover {
    color: gray;
}

#navbar-button {
    background-color: #3a6d61;
    border-radius: 25px;
    color: white;
    padding: 10px 20px;
    transition: background-color 0.3s;
    cursor: pointer;
    text-transform: none;

}


#navbar-button:hover {
    background-color: #333;
}


#inicio-button {
    background-color: #f0f0f0;
    border-radius: 50px;
    color: black;
    padding: 10px 5px;
    transition: background-color 0.3s;
    cursor: pointer;
    text-transform: none;
    border: 1px solid black;
}

#producto-button {
    background-color: #FCFCFC;
    border-radius: 50px;
    color: black;
    padding: 10px 5px;
    transition: background-color 0.3s;
    cursor: pointer;
    text-transform: none;
}

#producto-button:hover {
    background-color: #EFEFEF;
}

#inicio-button:hover {
    background-color: #EFEFEF;
}

.slider-cliente .slide-track-cliente {
    display: flex;
    animation: cliente-scroll 100s linear infinite;
    -webkit-animation: cliente-scroll 70s linear infinite;
    width: calc(200px * 26);
}

.slider-cliente .slide-cliente {
    width: 200px;
    margin-left: 20px;
    margin-right: 20px;
}

.slider-cliente .slide-cliente img {
    width: 100%;

}


.slider .slide-track {
    display: flex;
    animation: scroll 100s linear infinite;
    -webkit-animation: scroll 70s linear infinite;
    width: calc(200px * 26);
}

.slider .slide {
    width: 200px;
    margin-left: 20px;
    margin-right: 20px;
}

.slider .slide img {
    width: 100%;

}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-50%);
    }
}


@keyframes cliente-scroll {
    0% {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    100% {
        -webkit-transform: translateX(calc(-186.6px * 9));
        transform: translateX(calc(-186.6px *9));
    }
}



.productos-slider,
.productos-slider2 {
    overflow: hidden;
}

.productos-slide-track {
    display: flex;
    animation: productos-scroll 70s linear infinite;
    -webkit-animation: productos-scroll 70s linear infinite;
    width: calc(200px * 20);
}

.productos-slide-track2 {
    display: flex;
    animation: productos-scroll2 70s linear infinite;
    -webkit-animation: productos-scroll2 70s linear infinite;
    width: calc(200px * 20);
}





@keyframes fade-up {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Animación de aparición gradual de la imagen */
@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}



/* Clase para aplicar la animación de desvanecimiento desde abajo hacia arriba */
.fade-up {
    animation: fade-up 1s forwards;
}

/* Clase para aplicar la animación de aparición gradual de la imagen */
.fade-in {
    animation: fade-in 1s ease-out;
}


.fade-up-aboutus {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-up-aboutus.visible {
    opacity: 1;
    transform: translateY(0);
}

.fade-left {
    opacity: 0;
    transform: translateX(-20px);
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-left.visible {
    opacity: 1;
    transform: translateX(0);
}




.float {
    position: fixed;
    width: 63px;
    height: 63px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 45px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
    z-index: 2;
    text-decoration: none;
}

.float:hover {
    text-decoration: none;
    color: #FFF;
    background-color: #1ab152;
    animation: shake 1s;
    animation-iteration-count: infinite;
}

.proceso {
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.proceso.active {
    opacity: 1;
    transform: translateY(0);
}

body {
    background: linear-gradient(45deg, #EEEEEE, #EFEFEF, #EEEEEE);

}


.menu__link {
    color: #000000;
    line-height: 2;
    position: relative;
}

.menu__link::before {
    content: '';
    width: 0;
    height: 2px;
    border-radius: 2px;
    background-color: #000000;
    position: absolute;
    bottom: -.25rem;
    left: 0;
    transition: width .4s;
}

.menu__link:hover::before {
    width: 100%;
}

.btn-contacto {
    position: relative;
    font-size: 17px;
    text-transform: uppercase;
    text-decoration: none;
    padding: 1em 2.5em;
    display: inline-block;
    border-radius: 6em;
    transition: all .2s;
    border: none;
    font-family: inherit;
    font-weight: 500;
    color: black;
    background-color: white;
}

.button-contacto {
    padding: 15px 25px;
    border: unset;
    border-radius: 15px;
    color: #e8e8e8;
    z-index: 1;
    background: #212121;
    position: relative;
    -webkit-box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
    box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
    transition: all 250ms;
    overflow: hidden;
}

.button-contacto::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    border-radius: 15px;
    background-color: #2b2b2b;
    z-index: -1;
    -webkit-box-shadow: 4px 8px 19px -3px rgba(255, 255, 255, 0.27);
    box-shadow: 4px 8px 19px -3px rgba(255, 255, 255, 0.27);
    transition: all 250ms
}

.button-contacto:hover {
    color: #212121;
}

.button-contacto:hover::before {
    width: 100%;
}


.button-productoinicio {
    font-size: 16px;
    position: relative;
    margin: auto;
    padding: 1em 2.5em 1em 2.5em;
    border: none;
    background: #fff;
    transition: all 0.1s linear;
    box-shadow: 0 0.4em 1em rgba(0, 0, 0, 0.1);
}

.button-productoinicio:active {
    transform: scale(0.95);
}

.button-productoinicio span {
    color: #464646;
}

.button-productoinicio .border {
    position: absolute;
    border: 0.15em solid #fff;
    transition: all 0.3s 0.08s linear;
    top: 50%;
    left: 50%;
    width: 12.85em;
    height: 3.3em;
    transform: translate(-50%, -50%);
}

.button-productoinicio:hover .border {
    display: block;
    width: 13.7em;
    height: 4.2em;
}

.full-rounded {
    border-radius: 2em;
}

.loader {
    --dim: 3rem;
    width: var(--dim);
    height: var(--dim);
    position: relative;
    animation: spin988 2s linear infinite;
}

.loader .circle {
    --color: #333;
    --dim: 1.2rem;
    width: var(--dim);
    height: var(--dim);
    background-color: var(--color);
    border-radius: 50%;
    position: absolute;
}

.loader .circle:nth-child(1) {
    top: 0;
    left: 0;
}

.loader .circle:nth-child(2) {
    top: 0;
    right: 0;
}

.loader .circle:nth-child(3) {
    bottom: 0;
    left: 0;
}

.loader .circle:nth-child(4) {
    bottom: 0;
    right: 0;
}

@keyframes spin988 {
    0% {
        transform: scale(1) rotate(0);
    }

    20%,
    25% {
        transform: scale(1.3) rotate(90deg);
    }

    45%,
    50% {
        transform: scale(1) rotate(180deg);
    }

    70%,
    75% {
        transform: scale(1.3) rotate(270deg);
    }

    95%,
    100% {
        transform: scale(1) rotate(360deg);
    }
}


#btn-shine {
    position: relative;
    font-weight: 600;
    padding: 20px;
    color: transparent;
    background: linear-gradient(90deg, red, yellow, green, blue, indigo, violet);
    background-size: 200%;
    /* Tamaño ajustado para un movimiento sutil */
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    font-family: "Poppins", sans-serif;
    animation: rainbow 10s infinite linear;
    /* Animación más lenta y suave */
}

@keyframes rainbow {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}


#spline-viewer {
    width: 100%;
    height: 600px;
}

@media (max-width: 650px) {
    #spline-viewer {
        width: 100%;
        height: 400px;
    }
}








@import url('https://fonts.googleapis.com/css2?family=Kulim+Park:ital,wght@0,200;0,300;0,400;0,600;0,700;1,200;1,300;1,400;1,600;1,700&family=Manjari:wght@100;400;700&family=Plus+Jakarta+Sans:ital,wght@0,338;1,338&display=swap')